<template>

    <div class="app ">

        <div class="cres_ventas" >

          <div class="componente1"></div>
          
           <div class="sub_componente1"></div>

           <div class="top container-fluid">
            <div class="row">
              <div class="col-md-8 ">
                <div class="derecha ">
                  <h1 class="texto_1"> <b>Crece tus ventas</b> </h1>
                  <p class="texto3">Crecemos tus ventas online de forma rapida y sencilla</p>
                  <div class="tamaño_div">
                    <p class="texto2">Si no crecemos tus ventas en más de 50% el primer mes, no nos pagas nada </p>
                    <div class="position_linea">
                      <img class="linea_2 " src="../assets/componente_10.png" alt="">
                    </div>
                    <button class="btn color-b" @click="goToStep(0)"><b> MÁS INFORMACIÓN </b></button>
                  </div>
                    
                  
                </div>
                
              </div>
                <div class="col-md-4">
                  <div class="sub_componente_2"></div>
                </div>
            </div>
            </div>  
        
    </div>
    <div class="cres_ventas2">
          <cres_ventas2 />
        </div>
        <div class="cres_ventas3">
          <cres_ventas3 />
        </div>
        <div class="cres_ventas4">
          <cres_ventas4/>
        </div>
        </div>
    

</template>

<script>
import cres_ventas2 from '../components/cres_ventas2.vue'
import cres_ventas3 from '../components/cres_ventas3.vue'
import cres_ventas4 from '../components/cres_ventas4.vue'

export default {
 name: 'cres_ventas',
  components: {
    cres_ventas2,
    cres_ventas3,
    cres_ventas4,
  },
  data(){
      return{
          step: 1

      }
  },
  async mounted(){

  },
  methods:{
      goToStep(item){
          this.step=item

      },
      
  }
}
</script>
<style scoped>

.cres_ventas {
  display:flex ;
  height: 900px;
  position: relative;
  background-color: rgb(149,15,215);
  
}
.componente1{
  background-image: url("../assets/componente_1.png");
  width:295px;
  height:250px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;

  
} 
.sub_componente1{
  background-image: url("../assets/componente_1.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:275px;
  height:250px;
  right:0px ;
  top: 70%;
  transform: rotate(180deg);
}
.sub_componente_2{
  background-image: url("../assets/componente_9.png");
  position:relative;
  background-repeat: no-repeat;
  width:350px;
  height:400px;
  top: 20%;
  margin-left: -48%;

}
.div_position{
  text-align:left;
}
.telefono{
  width: 25%;
  height: 25%;
  
}
.top{
  position: absolute;
  top: 25%;
  }
.icon_W2{
  max-width: 450px;
  max-height: 450px;
  margin-bottom: 30%;
  
}
.chat_w{
  text-align: left;
  
}
.texto_1{
  font-size: 80px;
  color: rgb(255, 255, 255);
  text-align: left;
}
.texto2{
  font-size: 24px;
  text-align: left;
  color: rgb(255, 255, 255);

}
.texto3{
  font-size: 26px;
  text-align: left;
  color: rgb(237, 208, 252);

}
.apps2{
    max-height:200px;
    max-width: 200px;
}
.color-b{
      background-color: rgb(255, 255, 255);
      color: rgb(37, 113, 255);
      border-radius: 18px;
      width: 200px;
      height: 43px;
      font-size: 15px;
      margin-right:50%;
      }

.derecha{
  margin-left: 30%;
  
  
}
.tamaño_div{
  width: 65%;
}
.linea_2{
  height: 125px;
  
}
.position_linea{
  text-align: left;
}

@media only screen and (max-width: 780px) {
  .texto_1{
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;

}
.derecha{
    margin-left: 0%;
  }
.tamaño_div{
    width: 100%;
}
.texto2{
  font-size: 22px;
  text-align: center;
  color: rgb(255, 255, 255);

}
.texto3{
  font-size: 20px;
  text-align: center;
  color: rgb(237, 208, 252);

}
.linea_2{
  display: none;
}
.color-b{
      background-color: rgb(255, 255, 255);
      color: rgb(37, 113, 255);
      border-radius: 18px;
      width: 200px;
      height: 43px;
      font-size: 16px;
      margin-right:0%;
      
      }
      .sub_componente_2{
          display: none;

}
.sub_componente1{
  background-image: url("../assets/componente_1.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:275px;
  height:250px;
  right:0px ;
  top: 500px;
  transform: rotate(180deg);
}
.cres_ventas {
  display:flex ;
  height: 800px;
  position: relative;
  background-color: rgb(149,15,215);
  
}

}
@media only screen and (max-width: 800px) and (min-width: 780px) {
  .cres_ventas {
     height: 900px;
     
  }
  .texto_1{
    font-size: 50px;
  }
  .componente1{
  top: 5%; 
}
.top{
  top:20%;
} 
.derecha{
  margin-left: 10%;  
}
.sub_componente1{
  top: 60%;
  
}

}

</style>