import { render, staticRenderFns } from "./cres_ventas4.vue?vue&type=template&id=e88dd150&scoped=true&"
var script = {}
import style0 from "./cres_ventas4.vue?vue&type=style&index=0&id=e88dd150&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e88dd150",
  null
  
)

export default component.exports